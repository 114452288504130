import './github/code-scanning-alerts-search-input'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function bothChecked(): boolean {
  const defaultSetup = document.getElementById('checkbox_code_scanning_default_setup_enabled') as HTMLInputElement
  const advancedSetup = document.getElementById('checkbox_code_scanning_advanced_setup_enabled') as HTMLInputElement

  return defaultSetup.checked && advancedSetup.checked
}

function showDialog() {
  const switchDialog = document.getElementById('codeql-switch-to-new-setup-dialog') as HTMLDialogElement
  switchDialog.show()
}

on('change', '.js-repo-features-code-scanning-default-setup-form input[type=checkbox]', function () {
  if (bothChecked()) {
    showDialog()
  } else {
    ;(document.querySelector('.js-repo-features-code-scanning-default-setup-form') as HTMLFormElement).submit()
  }
})

on('change', '.js-repo-features-code-scanning-advanced-setup-form input[type=checkbox]', function () {
  if (bothChecked()) {
    showDialog()
  } else {
    ;(document.querySelector('.js-repo-features-code-scanning-advanced-setup-form') as HTMLFormElement).submit()
  }
})
